// extracted by mini-css-extract-plugin
export var carouselContainer = "G_vQ d_w d_H d_bf d_Z";
export var carouselContainerCards = "G_vR G_vQ d_w d_H d_bf d_Z";
export var carouselContainerSides = "G_vS d_w d_H d_Z";
export var prevCarouselItem = "G_vT d_w d_H d_0 d_k";
export var prevCarouselItemL = "G_vV G_vT d_w d_H d_0 d_k";
export var moveInFromLeft = "G_vW";
export var prevCarouselItemR = "G_vX G_vT d_w d_H d_0 d_k";
export var moveInFromRight = "G_vY";
export var selectedCarouselItem = "G_vZ d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "G_v0 G_vZ d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "G_v1 G_vZ d_w d_H d_Z d_bf";
export var nextCarouselItem = "G_v2 d_w d_H d_0 d_k";
export var nextCarouselItemL = "G_v3 G_v2 d_w d_H d_0 d_k";
export var nextCarouselItemR = "G_v4 G_v2 d_w d_H d_0 d_k";
export var arrowContainer = "G_v5 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "G_v6 G_v5 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "G_v7 G_v6 G_v5 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "G_v8 G_v5 d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "G_v9";
export var nextArrowContainerHidden = "G_wb G_v8 G_v5 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "G_kG d_0";
export var prevArrow = "G_wc G_kG d_0";
export var nextArrow = "G_wd G_kG d_0";
export var carouselIndicatorContainer = "G_wf d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "G_wg d_w d_bz d_bF";
export var carouselText = "G_wh d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "G_wj G_wh d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "G_wk d_b7";
export var carouselIndicator = "G_wl G_wk d_b7";
export var carouselIndicatorSelected = "G_wm G_wk d_b7";
export var arrowsContainerTopRight = "G_wn d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "G_wp d_0 d_bl d_bC";
export var arrowsContainerSides = "G_wq d_0 d_bl d_bC";
export var smallArrowsBase = "G_wr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "G_ws G_wr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "G_wt G_ws G_wr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "G_wv G_wr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "G_ww G_wv G_wr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "G_rn";
export var iconSmall = "G_wx";
export var multipleWrapper = "G_wy d_bC d_bF d_bf";
export var multipleImage = "G_wz d_bC";
export var sidesPrevContainer = "G_wB G_ws G_wr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "G_wC G_ws G_wr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";