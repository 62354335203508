// extracted by mini-css-extract-plugin
export var articleWrapper = "p_qP";
export var articleText = "p_qQ d_dv d_cs";
export var header = "p_qR d_Z d_w";
export var headerImageWrapper = "p_qS d_bd d_0 d_8 d_7 d_4 d_9 d_bk d_bR";
export var headerGradient = "p_qT d_bd d_0 d_8 d_7 d_4 d_9";
export var headerGradientOverlay = "p_qV d_bd d_0 d_8 d_7 d_4 d_9";
export var headerContentWrapper = "p_qW d_bz d_bP d_bD d_bJ d_bl d_Z";
export var headerContentWrapperAlt = "p_qX d_bz d_bP d_bD d_bJ d_bl d_Z";
export var contentWrapper = "p_mv d_w";
export var dateTag = "p_qY d_cf d_cr d_dq d_w";
export var icon1 = "p_qZ d_cP";
export var icon2 = "p_q0 d_cP d_cJ";
export var tagBtn = "p_q1 d_b1 d_cD d_dp d_dB";
export var headerText = "p_q2 d_w d_cf d_cs d_bW";
export var center = "p_q3 d_dw";
export var videoIframeStyle = "p_q4 d_d5 d_w d_H d_by d_b1 d_R";
export var articleImageWrapper = "p_q5 d_cs d_Z";
export var articleImageWrapperIcon = "p_q6 d_cs d_Z";
export var articleRow = "p_q7 d_bD";
export var quoteWrapper = "p_gQ d_bC d_bP d_cs";
export var quoteBar = "p_q8 d_H";
export var quoteText = "p_q9";
export var authorBox = "p_rb d_w";
export var authorRow = "p_rc d_bD d_bW d_cy";
export var separator = "p_rd d_w";
export var line = "p_fk d_w d_fk d_cY d_cy";
export var authorImage = "p_ln d_b7 d_Z d_w d_ct";
export var authorText = "p_rf d_ch d_ct";
export var masonryImageWrapper = "p_rg";
export var bottomSeparator = "p_rh d_w d_cy";
export var linksWrapper = "p_rj d_ch d_dv";
export var comments = "p_rk d_bD d_cy";
export var sharing = "p_rl d_bz d_bD d_bJ";
export var shareText = "p_rm d_w d_dw";
export var icon = "p_rn";
export var customRow = "p_rp d_bD d_Z";
export var text = "p_rq";
export var SubtitleSmall = "p_rr";
export var SubtitleNormal = "p_rs";
export var SubtitleLarge = "p_rt";