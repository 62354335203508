// extracted by mini-css-extract-plugin
export var alignLeft = "y_rv d_fp d_bG d_dv";
export var alignCenter = "y_bP d_fq d_bD d_dw";
export var alignRight = "y_rw d_fr d_bH d_dx";
export var testimonialsContainer = "y_s7 d_dW";
export var testimonialsContainerFull = "y_s8 d_dT";
export var testimonialsMainHeader = "y_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "y_jy d_jy";
export var testimonialsComponentSmall = "y_jx d_jx";
export var testimonialsComponentsWrapper = "y_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "y_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "y_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "y_s9 d_d7";
export var colEqualHeight = "y_tb d_bz";
export var testimonialsColumnWrapper = "y_jz d_jz d_b3";
export var testimonialsImageWrapper = "y_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "y_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "y_jr d_jr d_Z d_bz";
export var design2TextWrapper = "y_tc d_bC d_bP d_bJ";
export var design3 = "y_td d_cD d_H";
export var imageDesign2 = "y_tf";
export var SmallSmall = "y_tg C_tg C_tp C_tM";
export var SmallNormal = "y_th C_th C_tp C_tN";
export var SmallLarge = "y_tj C_tj C_tp C_tK";
export var exceptionWeight = "y_sn C_tR";