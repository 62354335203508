// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "x_sY d_bD";
export var storyRowWrapper = "x_hx d_hx d_bK";
export var storyLeftWrapper = "x_sZ d_bz d_bP";
export var storyWrapperFull = "x_s0 d_cD";
export var storyWrapperFullLeft = "x_pj d_cD d_bz d_bP d_bD";
export var contentWrapper = "x_mv d_hy";
export var storyLeftWrapperCenter = "x_s1 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "x_s2 d_hF";
export var storyHeader = "x_s3 d_hD d_w d_cs";
export var storyHeaderCenter = "x_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "x_hB d_hB d_by d_dw";
export var storyBtnWrapper = "x_s4 d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "x_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "x_s5 d_fg d_Z";
export var imageWrapperFull = "x_s6 d_w d_H d_bf d_Z";
export var SubtitleSmall = "x_rr C_rr C_tp C_tB";
export var SubtitleNormal = "x_rs C_rs C_tp C_tC";
export var SubtitleLarge = "x_rt C_rt C_tp C_tD";
export var textLeft = "x_dv";
export var textCenter = "x_dw";
export var textRight = "x_dx";