// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_sx d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_sy d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_sz d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_sB d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_sC d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_sD d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_sF d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_sG C_sG";
export var heroExceptionNormal = "v_sH C_sH";
export var heroExceptionLarge = "v_sJ C_sJ";
export var Title1Small = "v_sK C_sK C_tp C_tq";
export var Title1Normal = "v_sL C_sL C_tp C_tr";
export var Title1Large = "v_sM C_sM C_tp C_ts";
export var BodySmall = "v_sN C_sN C_tp C_tJ";
export var BodyNormal = "v_sP C_sP C_tp C_tK";
export var BodyLarge = "v_sQ C_sQ C_tp C_tL";